/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useCallback } from 'react';
import Home from './Home';
import Chain from './Chain';
import Block from './Block';
import BlockDetail from './Block/Detail';
import Transaction from './Transaction';
import TransactionDetail from './Transaction/Detail';
import ConsumerOriginator from './ConsumerOriginator/Detail.jsx';
import Collection from './Collection/Detail.jsx';
import Contract from './Contract';
import ContractDetail from './Contract/Detail';
import Node from './Node';
import Origin from './Origin';
import User from './User';
import style from './index.module.scss';
import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import ChainSelect from './components/ChainSelect';
import ImgChainMaker from '@imgs/chainmaker.png';
// import ImgFlower from '@imgs/flower.png';

export default function Main() {
    const { chainId } = useParams();
    const navigate = useNavigate();
    const updateChainId = useCallback((value) => {
        window.open(`/${value}/home`);
    }, []);
    const onInit = useCallback((value) => {
        setTimeout(() => {
            navigate(`/${value}/home`, { replace: true });
        }, 0);
    }, []);
    return (
        <>
            <div className={style.header}>
                <div className={style.header_c}>
                    <img
                        onClick={() => navigate(`/${chainId}/home`)}
                        src={ImgChainMaker}
                        className={style.header_logo}
                    />
                    <div className={style.header_setting}>
                        <ChainSelect value={chainId} onChange={updateChainId} onInit={onInit} />
                        {/* <img onClick={() => navigate(`/${chainId}/chain`)} src={ImgFlower} className={style.header_seeting_icon} /> */}
                    </div>
                </div>
            </div>
            {chainId && chainId !== 'v' && (
                <Routes>
                    <Route path="home" element={<Home />} />
                    <Route path="node" element={<Node />} />
                    <Route path="chain" element={<Chain />} />
                    <Route path="block" element={<Block />} />
                    <Route path="block/:blockHeight" element={<BlockDetail />} />
                    <Route path="transaction" element={<Transaction />} />
                    <Route path="transaction/:txId" element={<TransactionDetail />} />

                    <Route path="consumer-originator/:txId" element={<ConsumerOriginator />} />
                    <Route path="collection/:txId" element={<Collection />} />
 
                    <Route path="contract" element={<Contract />} />
                    <Route path="contract/:contractName" element={<ContractDetail />} />
                    <Route path="origin" element={<Origin />} />
                    <Route path="user" element={<User />} />
                    <Route path="*" element={<Navigate to="home" />} />
                </Routes>
            )}
        </>
    );
}
