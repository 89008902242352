import React, { useEffect, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { transformBase64ToStr } from '@src/utils/tools';
import detailstyle from '../detail.module.scss';
import { GetCollectionDetail } from '@src/utils/apis';

import { Form, Table, Card } from 'tea-component';
import { useParams } from 'react-router';
import { TxInfo } from '@src/models';
import { Link } from 'react-router-dom';

export default function CollectionDetail() {
    const { chainId, txId, type } = useParams();
    const [detail, setDetail] = useState(null);
    useEffect(() => {
        GetCollectionDetail({ ChainId: chainId, CommodityHash: txId }).then((res) => {
            console.log(res);

            if (res.Data) {
                setDetail(res.Data);
            }
        });
    }, [chainId, txId]);

    return (
        <PageBox title="藏品信息">
            {(detail?.type == 'COMMODITY' || detail?.type == 'COMPOSITION') && (
                <div className={detailstyle.detail}>
                    <Form.Title>基础信息</Form.Title>
                    <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
                        <Form.Item label="藏品名称">
                            <Form.Text>{detail?.commodityName || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="藏品哈希">
                            <Form.Text>{detail?.mcommodityHash || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="藏品编号">
                            <Form.Text>{detail?.commodityId || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="代理方">
                            <Form.Text>{detail?.AgentAddr || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="创作者">
                            <Form.Text>{detail?.CreatorAddr || '--'}</Form.Text>
                        </Form.Item>
                    </Form>

                    {detail?.compositions?.length > 0 && (
                        <>
                            <Form.Title>合成消耗</Form.Title>
                            <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
                                {detail?.compositions?.map((item, index) => {
                                    return (
                                        <Form.Item label="藏品哈希" key={index}>
                                            <Form.Text>{item || '--'}</Form.Text>
                                        </Form.Item>
                                    );
                                })}
                            </Form>
                        </>
                    )}

                    <Form.Title>流转信息</Form.Title>
                    <div style={{ padding: 10, marginLeft: -10, height: 'calc(100vh - 410px)', overflowY: 'auto' }}>
                        {detail?.transferHistory?.map((item, index) => {
                            return (
                                <Card style={{ padding: '1px 16px 16px' }} key={index}>
                                    <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
                                        <Form.Item label="流转类型">
                                            <Form.Text>{item?.comment || '--'}</Form.Text>
                                        </Form.Item>

                                        <Form.Item label="流转时间">
                                            <Form.Text>{item?.transTime || '--'}</Form.Text>
                                        </Form.Item>

                                        <Form.Item label="交易哈希">
                                            <Form.Text>{item?.transHash || '--'}</Form.Text>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            );
                        })}
                    </div>
                </div>
            )}

            {detail?.type == 'MCOMMODITY' && (
                <div className={detailstyle.detail}>
                    <Form.Title>基础信息</Form.Title>
                    <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
                        <Form.Item label="藏品名称">
                            <Form.Text>{detail?.McommodityName || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="藏品哈希">
                            <Form.Text>{detail?.McommodityHash || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="藏品编号">
                            <Form.Text>{detail?.McommodityId || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="铸造时间">
                            <Form.Text>{detail?.CreateTime || '--'}</Form.Text>
                        </Form.Item>
                        <Form.Item label="发行量">
                            <Form.Text>{detail?.CommodityAmount || '--'}</Form.Text>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </PageBox>
    );
}
