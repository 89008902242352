/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */


import { Base64 } from 'js-base64'

export const transformBase64ToStr = (base: string): string => {
  try {
    const decode = Base64.decode(base);
    const str = decodeURI(decode);
    return str;
  } catch (e) {
    return base;
  }
};
