import React, { useEffect, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { transformBase64ToStr } from '@src/utils/tools';
import detailstyle from '../detail.module.scss';
import { GetConsumerOriginator } from '@src/utils/apis';

import { Form, Table, Card } from 'tea-component';
import { useParams } from 'react-router';
import { TxInfo } from '@src/models';
import { Link } from 'react-router-dom';

export default function TransactionDetail() {
    const { chainId, txId } = useParams();
    const [detail, setDetail] = useState(null);
    useEffect(() => {
        GetConsumerOriginator({ ChainId: chainId, UserAddr: txId }).then((res) => {
            console.log(res);

            if (res.Data) {
                setDetail(res.Data);
            }
        });
    }, [chainId, txId]);

    return (
        <PageBox title="账户信息">
            <div className={detailstyle.detail}>
                <Form.Title>基础信息</Form.Title>
                <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
                    <Form.Item label="账户地址">
                        <Form.Text>{detail?.userAddr || '--'}</Form.Text>
                    </Form.Item>
                    <Form.Item label="注册时间">
                        <Form.Text>{detail?.createTime || '--'}</Form.Text>
                    </Form.Item>
                </Form>
                <Form.Title>持有藏品</Form.Title>
                <div style={{ padding: 10, marginLeft: -10, height: 'calc(100vh - 410px)', overflowY: 'auto' }}>
                    {(detail?.collections || detail?.publishMcommodity)?.map((item, index) => {
                        return (
                            <Card style={{ padding: '1px 16px 16px' }} key={index}>
                                <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
                                    <Form.Item label="藏品名称">
                                        <Form.Text>{item?.commodityName || item?.mcommodityName || '--'}</Form.Text>
                                    </Form.Item>

                                    <Form.Item label="藏品哈希">
                                        <Form.Text>{item?.commodityHash || item?.mcommodityHash || '--'}</Form.Text>
                                    </Form.Item>

                                    {detail.type == 'CUSTOMER' && (
                                        <Form.Item label="藏品编号">
                                            <Form.Text>{item?.commodityId || item?.mcommodityId || '--'}</Form.Text>
                                        </Form.Item>
                                    )}
                                </Form>
                            </Card>
                        );
                    })}
                </div>
            </div>
        </PageBox>
    );
}
